import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
// import { parseISO, format, isSameYear, isToday, formatDistance, setDefaultOptions, getTime } from 'date-fns'
import { parseISO, format, isSameYear, isToday, formatDistanceStrict, getTime, intervalToDuration } from 'date-fns'
// import { cs } from 'date-fns/locale'


// import { FiSettings } from 'react-icons/fi';
// import { FaArrowsAltH } from 'react-icons/fa'
import { FaCarBattery, FaKey, FaMapMarkedAlt, FaTachometerAlt, FaThermometer, FaBatteryHalf, FaCopy, FaParking, FaRoute } from 'react-icons/fa'
import { BsSpeedometer, BsDoorOpen } from 'react-icons/bs'
import { IoMdPerson } from "react-icons/io";
import { BiSolidTimer } from 'react-icons/bi'
import { FaGasPump } from "react-icons/fa6";
import { GiWeight } from "react-icons/gi";

import { isBrand } from "../tools";
import { Park, Ign } from "../../images"
import { iconKind } from "../../images/kind"
import { formatDistance, formatDecimalSeparator } from "../RidesView/ridesRows"
import "./DeviceView.css"

export default function DeviceView({ devFocus, height, width, toast }) {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'devices' });
    const gps = (devFocus.pos[0] / 3600000.0).toFixed(6) + ',' + (devFocus.pos[1] / 3600000.0).toFixed(6);
    const timeDur = devFocus.currentridestamp ? intervalToDuration({ start: parseISO(devFocus.currentridestamp), end: new Date() }) : null
    let parkStamp = '';
    if (devFocus.ridestamp) {
        parkStamp = formatDistanceStrict(parseISO(devFocus.ridestamp), new Date());
        if (parkStamp === 'hodina') parkStamp = 'hodinu';
        if (parkStamp === 'minuta') parkStamp = 'minutu';
    }
    if (!devFocus) return <></>
    return (
        <div className="deviceView" style={{ height: height - 5, width: width, overflow: 'auto' }}>
            <div className="d-flex flex-row justify-content-evenly mb-1">
                {iconKind(devFocus)}
                <div className="d-flex justify-content-center align-items-start flex-column ms-2">
                    <div className="fw-bold p-1 text-center">{devFocus.name}</div>
                    {devFocus.plate !== '-' & devFocus.plate !== ' ' ? (
                        <div className="device-plate cursorpointer ms-1"
                            onClick={() => { navigator.clipboard.writeText(devFocus.plate); toast.success(devFocus.plate + ' ' + i18n.t('page.copy')) }} >
                            {devFocus.plate}
                        </div>) : ''}
                </div>
            </div>
            <table className="table mx-auto px-1 max-w-50vh">
                <tbody className="fs-7 ">
                    <tr>
                        <td colSpan="2" className="text-secondary">
                            {devFocus.status & 1 ? (<span>
                                {!devFocus.isstatic ? (
                                    <div>
                                        <div>
                                            <span className="fw-bold text-primary me-2">{t('nowRide') + ":"}</span>
                                            {devFocus.currentridedist ? (<span>
                                                <FaRoute className="text-secondary ms-2 mb-1 fs-6" />
                                                <span className="mx-1">{i18n.t('stats.distance')}</span>
                                                <span className="fw-bold text-primary">{Math.round(devFocus.currentridedist / 1000).toLocaleString() + ' km'}</span></span>) : ""}
                                        </div>
                                        {timeDur ? (<span>
                                            <BiSolidTimer className="text-secondary me-1 mb-1 fs-5" />
                                            {i18n.t('stats.time')}
                                            <span className="fw-bold text-primary ms-1" title="hh:mm">{(timeDur.hours).toString().padStart(2, '0') + ":" + (timeDur.minutes).toString().padStart(2, '0')}</span></span>) : ""}
                                        <FaTachometerAlt className="text-secondary me-1 mb-1 fs-6 ms-3" />
                                        {i18n.t('devices.speed')}
                                        <span className="fw-bold text-primary ms-1">{devFocus.pos[3] + ' km/h'}</span>
                                    </div>
                                ) : <span className="fw-bold text-primary">{devFocus.cfg && devFocus.cfg.includes('doorSens') ? t('doorSensOpen') : t('on')}</span>}
                            </span>) : (<span>
                                {!devFocus.isstatic ? <FaParking className="fs-5 mb-1" /> : ""} <span className="fw-bold text-primary">{!devFocus.isstatic ? t('park') : (devFocus.cfg && devFocus.cfg.includes('doorSens') ? t('doorSensClose') : t('off'))} {parkStamp}</span>
                                {devFocus.ridestamp ? (<span className="ms-2">{"("}{isToday(parseISO(devFocus.ridestamp)) ? format(parseISO(devFocus.ridestamp), 'HH:mm') : (format(parseISO(devFocus.ridestamp), 'dd.MM.yyyy HH:mm'))}{")"}</span>) : ""}
                            </span>)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" className='fs-7'>
                            {<FaMapMarkedAlt className="p-0 fs-6 me-1 mb-1 text-secondary cursorpointer click" onClick={() => { window.open(`https://www.google.com/maps?q=${gps}`, '_blank') }} title={i18n.t('maps.openGoogle')} />}
                            {devFocus.address}
                            {<FaCopy onClick={() => { navigator.clipboard.writeText(gps); toast.success(gps + ' ' + i18n.t('page.copy')) }} className="text-secondary cursorpointer click ms-2 mb-1 fs-7" title={i18n.t('maps.copyGPS')} />}
                        </td>
                    </tr>
                    {devFocus.drivername ? <tr>
                        <td className="text-secondary"><IoMdPerson className="p-0 fs-5 me-2" />{t('drivername')}</td>
                        <td className="fw-bold fs-7">{devFocus.drivername}</td>
                    </tr> : ''}
                    {!devFocus.isstatic ? (
                        <tr>
                            <td className="text-secondary"><BsSpeedometer className="p-0 fs-5 me-1" /> {t('tachometer')}</td>
                            <td className="fw-bold fs-7">{formatDistance(devFocus.tacho) + " km"}</td>
                        </tr>) : ""}
                    {devFocus.astatus.weight ? <tr>
                        <td className="text-secondary"><GiWeight className="p-0 fs-5 me-1" /> {t('weight')}</td>
                        <td className="fw-bold fs-7">{parseInt(devFocus.astatus.weight).toLocaleString() + " kg"}</td>
                    </tr> : ''}
                    {devFocus.astatus.pwr1 ? <tr>
                        <td className="text-secondary"><FaCarBattery className="p-0 fs-5 me-1" /> {t('pwr1')}</td>
                        <td className="fw-bold fs-7">{formatDecimalSeparator(parseFloat(devFocus.astatus.pwr1)) + " V"}</td>
                    </tr> : ''}
                    {devFocus.astatus.bat ? <tr>
                        <td className="text-secondary"><FaBatteryHalf className="p-0 fs-5 me-1" /> {t('bat')}</td>
                        <td className="fw-bold fs-7">{devFocus.astatus.bat + " %"}</td>
                    </tr> : ''}
                    {!devFocus.isstatic && devFocus.cfg && devFocus.cfg.includes('doorSens') ? <tr>
                        <td className="text-secondary"><BsDoorOpen className="p-0 fs-6 me-2" />{t('doorSens')}</td>
                        <td className="fw-bold fs-7">{(devFocus.status & 4) ? t('doorSensOpen') : t('doorSensClose')}</td>
                    </tr> : ''}
                    {devFocus.astatus.tmp1 ? <tr>
                        <td className="text-secondary"> <FaThermometer className="p-0 me-1" />{t('temperature') + ' ' + (devFocus.sensors.tmp1 ? devFocus.sensors.tmp1 : 1)}</td>
                        <td className="fw-bold fs-7">{devFocus.astatus.tmp1 + " ℃"}</td>
                    </tr> : ''}
                    {devFocus.astatus.tmp5 ? <tr>
                        <td className="text-secondary"><FaThermometer className="p-0 me-1" /> {t('temperature') + ' ' + (devFocus.sensors.tmp5 ? devFocus.sensors.tmp5 : 5)}</td>
                        <td className="fw-bold fs-7">{devFocus.astatus.tmp5 !== '-100.0' ? devFocus.astatus.tmp5 + " ℃" : t('unavailable')}</td>
                    </tr> : ''}
                    {devFocus.astatus.tmp6 ? <tr>
                        <td className="text-secondary"><FaThermometer className="p-0 me-1" /> {t('temperature') + ' ' + (devFocus.sensors.tmp6 ? devFocus.sensors.tmp6 : 6)}</td>
                        <td className="fw-bold fs-7">{devFocus.astatus.tmp6 !== '-100.0' ? devFocus.astatus.tmp6 + " ℃" : t('unavailable')}</td>
                    </tr> : ''}
                    {devFocus.astatus.tmp7 ? <tr>
                        <td className="text-secondary"><FaThermometer className="p-0 me-1" /> {t('temperature') + ' ' + (devFocus.sensors.tmp7 ? devFocus.sensors.tmp7 : 7)}</td>
                        <td className="fw-bold fs-7">{devFocus.astatus.tmp7 !== '-100.0' ? devFocus.astatus.tmp7 + " ℃" : t('unavailable')}</td>
                    </tr> : ''}
                    {devFocus.astatus.tmp8 ? <tr>
                        <td className="text-secondary"><FaThermometer className="p-0 me-1" /> {t('temperature') + ' ' + (devFocus.sensors.tmp8 ? devFocus.sensors.tmp8 : 8)}</td>
                        <td className="fw-bold fs-7">{devFocus.astatus.tmp8 !== '-100.0' ? devFocus.astatus.tmp8 + " ℃" : t('unavailable')}</td>
                    </tr> : ''}

                </tbody>
            </table>
        </div>
    )
}
