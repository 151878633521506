import octavia from './octavia.png';
import octavia_b from './octavia_black.png';
import octavia_s from './octavia_silver.png';
import octavia_m from './octavia_mask.png';
import transit from './transit.png';
import transit_b from './transit_black.png';
import transit_s from './transit_silver.png';
import transit_m from './transit_mask.png';
import fabia from './fabia.png';
import fabia_b from './fabia_black.png';
import fabia_s from './fabia_silver.png';
import fabia_m from './fabia_mask.png';
import auris from './auris.png';
import auris_b from './auris_black.png';
import auris_s from './auris_silver.png';
import auris_m from './auris_mask.png';
import cclass from './cclass.png';
import cclass_b from './cclass_black.png';
import cclass_s from './cclass_silver.png';
import cclass_m from './cclass_mask.png';
import truckbig from './truck.png';
import truckbig_b from './truck_black.png';
import truckbig_s from './truck_silver.png';
import truckbig_m from './truck_mask.png';
import machinemove from './machine.png';
import bike from './bike.png';
import bike_b from './bike_black.png';
import bike_s from './bike_silver.png';
import ship from './boat.png';
import ship_b from './boat_black.png';
import ship_s from './boat_silver.png';
import autobus from './bus.png';
import autobus_b from './bus_black.png';
import autobus_s from './bus_silver.png';
import motorbike from './moto.png';
import motorbike_b from './moto_black.png';
import motorbike_s from './moto_silver.png';
import caddy from './panelvan.png';
import caddy_b from './panelvan_black.png';
import caddy_s from './panelvan_silver.png';
import zetor from './tractor.png';
import house from './building.png';
import tmp from './temperature.png';
import tmp_alfakomtemp from '../../brand/alfakomtemp/images/temperature.png';
import personi from './person.png';
import "./kind.css"

let tmpIcon = tmp;
if (process.env.REACT_APP_BRAND === 'alfakomtemp') tmpIcon = tmp_alfakomtemp;

export function HextoFilter(hex) {
    let r = parseInt(hex.substr(1, 2), 16);
    let g = parseInt(hex.substr(3, 2), 16);
    let b = parseInt(hex.substr(5, 2), 16);

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max == min) {
        h = s = 0;
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    return [Math.round(h * 360), s, l];
}

export function iconKind(d, small) {
    const f = HextoFilter('#' + d.icolor)
    let black;
    let color;

    if (f[0] == 0 & f[1] == 0) {
        color = false;
        if (f[2] < 120) {
            if (f[2] < 120) black = true;
        }
    }
    else color = true;

    const supportIcon = ['vehicle', 'hatchback', 'taxi', 'wagon', 'van', 'truck', 'machine', 'bicycle', 'boat', 'bus', 'moto', 'panelvan', 'tractor', 'building', 'temperature', 'person']
    const checkSupport = supportIcon.find(icon => { return icon === d.icon })
    const mask = ['vehicle', 'hatchback', 'taxi', 'wagon', 'van', 'truck']
    const checkMask = mask.find(icon => { return icon === d.icon })
    if (checkSupport) {
        const vehicle = octavia;
        const vehicle_b = octavia_b;
        const vehicle_s = octavia_s;
        const vehicle_m = octavia_m;
        const hatchback = fabia;
        const hatchback_b = fabia_b;
        const hatchback_s = fabia_s;
        const hatchback_m = fabia_m;
        const taxi = auris;
        const taxi_b = auris_b;
        const taxi_s = auris_s;
        const taxi_m = auris_m;
        const wagon = cclass;
        const wagon_b = cclass_b;
        const wagon_s = cclass_s;
        const wagon_m = cclass_m;
        const van = transit;
        const van_b = transit_b;
        const van_s = transit_s;
        const van_m = transit_m;
        const truck = truckbig;
        const truck_b = truckbig_b;
        const truck_s = truckbig_s;
        const truck_m = truckbig_m;
        const machine = machinemove;
        const machine_b = machinemove;
        const machine_s = machinemove;
        const bicycle = bike;
        const bicycle_b = bike_b;
        const bicycle_s = bike_s;
        const boat = ship;
        const boat_b = ship_b;
        const boat_s = ship_s;
        const bus = autobus;
        const bus_b = autobus_b;
        const bus_s = autobus_s;
        const moto = motorbike;
        const moto_b = motorbike_b;
        const moto_s = motorbike_s;
        const panelvan = caddy;
        const panelvan_b = caddy_b;
        const panelvan_s = caddy_s;
        const tractor = zetor;
        const tractor_b = zetor;
        const tractor_s = zetor;
        const building = house;
        const building_b = house;
        const building_s = house;
        const person = personi;
        const person_b = personi;
        const person_s = personi;
        const temperature = tmpIcon;

        const nameIcon = color ? d.icon : (black ? d.icon + '_b' : d.icon + '_s')
        if (d.gitem2 || d.gitem1) var domain = (localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).domain : JSON.parse(sessionStorage.getItem('login')).domain);
        if (small) {
            return <img
                alt={d.icon} src={!d.gitem1 ? eval(nameIcon) : 'https://' + domain + '.logiscare.com/' + d.gitem1}
                className=""
                style={d.icon !== 'tractor' && d.icon !== 'machine' && d.icon !== 'building' && d.icon !== 'temperature' && d.icon !== 'person'? { filter: 'hue-rotate(' + f[0] + 'deg)', width: '90%', paddingLeft: '10px' } : { width: '90%', paddingLeft: '10px' }}
            />
        }
        else {
            return <div
                className={`kind-mask ${(d.icon !== 'bicycle' && d.icon !== 'moto') ? 'mt-2' : ''} `}
                style={!d.gitem2 && checkMask ? {
                    backgroundImage: `url(${eval(d.icon + '_m') ? eval(d.icon + '_m') : console.log('neni')
                        })`
                } : {}}
            >
                <img
                    alt={d.icon} src={!d.gitem2 ? eval(nameIcon) : 'https://' + domain + '.logiscare.com/' + d.gitem2}
                    className={!d.gitem2 ? "kind-vehicle-icon" : "kind-image"}
                    style={!d.gitem2 && d.icon !== 'tractor' && d.icon !== 'machine' && d.icon !== 'building' && d.icon !== 'temperature' && d.icon !== 'person' ? { filter: 'hue-rotate(' + f[0] + 'deg)' } : {}}
                />
            </div>
        }
    }
}
