import { parseISO, format, isToday, setDayOfYear, parse } from 'date-fns'
import { formatDistance, formatDecimalSeparator, formatDurationOver24 } from "../RidesView/ridesRows"
import * as Sentry from "@sentry/react";
import i18n from '../../dictionary/i18n';
import { getLabel } from './statsColumns';

const getRating = (type, value) => {
    if (value < 0 || !value) value = 0;
    const general = [
        { limit: 460, point: 1 }, //100-46%
        { limit: 410, point: 2 }, //45-41%
        { limit: 360, point: 3 }, //40-36%
        { limit: 310, point: 4 }, //35-31%
        { limit: 260, point: 5 }, //30-26%
        { limit: 210, point: 6 }, //25-21%
        { limit: 160, point: 7 }, //20-16% 
        { limit: 110, point: 8 }, //15-11% 
        { limit: 60, point: 9 },  //10-6%
        { limit: 0, point: 10 },  //5-0%
    ];

    const fuelZero = [
        { limit: 410, point: 10 }, // 100-41%
        { limit: 360, point: 9 },  // 40-36%
        { limit: 310, point: 8 },  // 35-31%
        { limit: 260, point: 7 },  // 30-26%
        { limit: 210, point: 6 },  // 25-21%
        { limit: 160, point: 5 },  // 20-16%
        { limit: 110, point: 4 },  // 15-11%
        { limit: 60, point: 3 },   // 10-6%
        { limit: 10, point: 2 },   // 5-1%
        { limit: 0, point: 1 },   // 0%
    ];

    const cbrake = [
        { limit: 100, point: 1 },
        { limit: 85, point: 2 },
        { limit: 75, point: 3 },
        { limit: 65, point: 4 },
        { limit: 55, point: 5 },
        { limit: 45, point: 6 },
        { limit: 35, point: 7 },
        { limit: 25, point: 8 },
        { limit: 15, point: 9 },
        { limit: 0, point: 10 },
    ];

    const dbrake = [
        { limit: 50, point: 1 },
        { limit: 45, point: 2 },
        { limit: 40, point: 3 },
        { limit: 35, point: 4 },
        { limit: 30, point: 5 },
        { limit: 25, point: 6 },
        { limit: 20, point: 7 },
        { limit: 15, point: 8 },
        { limit: 10, point: 9 },
        { limit: 0, point: 10 },
    ];

    const dBrakeM = [
        { limit: 950, point: 10 },
        { limit: 900, point: 9 },
        { limit: 850, point: 8 },
        { limit: 800, point: 7 },
        { limit: 750, point: 6 },
        { limit: 700, point: 5 },
        { limit: 650, point: 4 },
        { limit: 600, point: 3 },
        { limit: 550, point: 2 },
        { limit: 0, point: 1 },
    ];

    const cruiser = [
        { limit: 610, point: 10 },
        { limit: 510, point: 9 },
        { limit: 460, point: 8 },
        { limit: 410, point: 7 },
        { limit: 360, point: 6 },
        { limit: 310, point: 5 },
        { limit: 260, point: 4 },
        { limit: 210, point: 3 },
        { limit: 150, point: 2 },
        { limit: 0, point: 1 },
    ];

    const predict = [
        { limit: 410, point: 10 },
        { limit: 360, point: 9 },
        { limit: 310, point: 8 },
        { limit: 260, point: 7 },
        { limit: 210, point: 6 },
        { limit: 160, point: 5 },
        { limit: 110, point: 4 },
        { limit: 60, point: 3 },
        { limit: 10, point: 2 },
        { limit: 0, point: 1 },
    ];

    const typeList = {
        'rpmIdle': general,
        'fuelZero': fuelZero,
        'cbrake': cbrake,
        'dbrake': dbrake,
        'dBrakeM': dBrakeM,
        'cruiser': cruiser,
        'speedkm': general,
        'rpmExce': general,
        'predict': predict
    };

    const rating = typeList[type].find(item => value >= ((2 - 10 / 10) * item.limit)); // (2-vaha/10)* limit
    return rating.point
}

export const getRowsDriverCheck = ((d, excelExport, columns, typeTruck) => {
    try {
        if (!d) return null
        let res = []

        const calculationMark = (a) => {
            const d = 100 / (a[0] + a[1] + a[2] + a[3] + a[4] + a[5] + a[6] + a[7] + a[8] + a[9]);
            const res = ((1 * (a[0] * d / 100)) + (2 * (a[1] * d / 100)) + (3 * (a[2] * d / 100)) + (4 * (a[3] * d / 100)) + (5 * (a[4] * d / 100)) + (6 * (a[5] * d / 100)) + (7 * (a[6] * d / 100)) + (8 * (a[7] * d / 100)) + (9 * (a[8] * d / 100)) + (10 * (a[9] * d / 100))) / 1
            if (res) return formatDistance(11 - res);
            else return formatDistance(10)
        }

        d.list.forEach((d) => {
            const fuelZeroV = d.fuelzero !== d.dride ? d.fuelzero : ((d.dride) - (d.load));
            const rpmIdle = d.rpmidl > 0 ? {
                percent: formatDistance(d.rpmidl / d.engine * 100) + ' % ',
                rating: getRating('rpmIdle', (d.rpmidl / d.engine * 1000)),
                value: formatDurationOver24(d.rpmidl * 1000, true)
            } : {}
            const fuelZero = d.engine > 0 && fuelZeroV !== d.dride ? {
                percent: formatDistance(fuelZeroV / d.engine * 100) + ' % ',
                rating: getRating('fuelZero', (fuelZeroV / d.engine * 1000)),
                value: formatDurationOver24(fuelZeroV * 1000, true)
            } : {}
            const cbrake = d.cbrake > 0 ? {
                value: ((100000 / d.dist) * d.cbrake).toLocaleString(undefined, { maximumFractionDigits: 0 }) + 'x',
                rating: getRating('cbrake', ((100000 / d.dist) * d.cbrake)),
            } : {}
            const dbrake = d.dbrake > 0 ? {
                percent: formatDistance(d.dbrake / d.dride * 100) + ' % ',
                rating: getRating('dbrake', (d.dbrake / d.dride * 1000)),
                value: formatDurationOver24(d.dbrake * 1000, true)
            } : {}

            const dBrakeM = d.dbrakem > 0 && d.dbrakem < d.dride ? {
                percent: formatDistance(d.dbrakem / d.dride * 100) + ' % ',
                rating: getRating('dBrakeM', (d.dbrakem > 0 ? parseInt((100 - ((d.dbrake / d.dride * 1000) / (d.dbrakem / d.dride * 1000) * 10)) * 10) : 0)),
                value: formatDurationOver24(d.dbrakem * 1000, true)
            } : {}
            const cruiser = d.cruiser > 0 && (d.cruiser + d.continuity) < d.dride ? {
                percent: formatDistance((d.cruiser + d.continuity) / d.dride * 100) + ' % ',
                rating: getRating('cruiser', ((d.cruiser + d.continuity) / d.dride * 1000)),
                value: formatDurationOver24((d.cruiser + d.continuity) * 1000, true),
            } : {}
            const speedkm = d.speedkm > 0 ? {
                percent: formatDistance(d.speedkm / d.dist * 100) + ' % ',
                rating: getRating('speedkm', (d.speedkm / d.dist * 1000)),
                value: formatDistance(d.speedkm / 1000) + ' km'
            } : {}

            const rpmExce = d.rpmexce > 0 ? {
                percent: formatDistance(d.rpmexce / d.dride * 100) + ' % ',
                rating: getRating('rpmExce', (d.rpmexce / d.dride * 1000)),
                value: formatDurationOver24(d.rpmexce * 1000, true)
            } : {}

            const predict = d.predict > 0 ? {
                percent: formatDistance(d.predict / d.dride * 100) + ' % ',
                rating: getRating('predict', (d.predict / d.dride * 1000)),
                value: formatDurationOver24(d.predict * 1000, true)
            } : {}

            const sur = 100 / (d.f1 + d.f2 + d.f3 + d.f4 + d.f5 + d.f6 + d.f7);
            const ratingArr = [rpmIdle, fuelZero, cbrake, dbrake, dBrakeM, cruiser, speedkm, rpmExce, predict];
            const rate = typeTruck ? (ratingArr.reduce((acc, curVal) => acc + (curVal.rating ? curVal.rating : 0), 0) / ((ratingArr.length * 10)) * 100) : d.mark2 ? d.mark2 : (d.mark ? d.mark : 0);

            const data = ({
                'driver': d.drivername,
                'name': !excelExport ? d.devicesname : d.devicesname ? d.devicesname.join(', ') : '',
                'plate': !excelExport ? d.devicesplate : d.devicesplate ? d.devicesplate.join(', ') : '',
                'stamps': format(parseISO(d.stamp1), 'dd.MM.yyyy HH:mm') + (!excelExport ? ';' : ', ') + format(parseISO(d.stamp2), 'dd.MM.yyyy HH:mm'),
                'distance': formatDistance(d.dist / 1000) + ' km',
                'fuelTotal': formatDecimalSeparator(d.fuel / 1000) + ' l',
                'average_fuel': formatDecimalSeparator((100000 / d.dist) * (d.fuel / 1000)) + ' l/100km',
                'engine': formatDurationOver24(d.engine * 1000, true),
                'dride': formatDurationOver24(d.dride * 1000, true),
                'load': d.load > 0 ? (formatDistance(d.load / d.dride * 100) + ' %;' + formatDurationOver24(d.load * 1000, true)) + ';' : 'undefined;;',
                'cbrake': cbrake.value + '; ;' + dbrake.rating,
                'dbrake': dbrake.percent + ';' + dbrake.value + ';' + dbrake.rating,
                'dBrakeM': dBrakeM.percent + ';' + dBrakeM.value + ';' + dBrakeM.rating,
                'cruiser': cruiser.percent + ';' + cruiser.value + ';' + cruiser.rating,
                'speedkm': speedkm.percent + ';' + speedkm.value + ';' + speedkm.rating,
                'rpmExce': rpmExce.percent + ';' + rpmExce.value + ';' + rpmExce.rating,
                'rpmIdle': rpmIdle.percent + ';' + rpmIdle.value + ';' + rpmIdle.rating,
                'fuelIdle': d.fuelidl / 1000 + ' l',
                'fuelZero': fuelZero.percent + ';' + fuelZero.value + ';' + fuelZero.rating,
                'weight': formatDistance(d.weight / 1000) + ' t',
                'rpmAvr': d.rpm + ' rpm',
                'maxSpeed': d.maxspeed + ' km',
                'alevationUp': formatDistance(d.alevationup / 100) + ' km',
                'rating': formatDecimalSeparator(rate) + ';' + Math.round(rate / 100 * 10), //+' - ' +ratingArr.reduce((acc, curVal) => acc + curVal.rating, 0) +'b z '+(ratingArr.length*10)
                'startUps': ((100000 / d.dist) * d.startups).toLocaleString(undefined, { maximumFractionDigits: 0 }) + 'x',
                'predict': predict.percent + ';' + predict.value + ';' + predict.rating,
                'acceleration': calculationMark([d.x1, d.x2, d.x3, d.x4, d.x5, d.x6, d.x7, d.x8, d.x9, d.x10]),
                'breaking': calculationMark([d.y1, d.y2, d.y3, d.y4, d.y5, d.y6, d.y7, d.y8, d.y9, d.y10]),
                'cornering': calculationMark([d.z1, d.z2, d.z3, d.z4, d.z5, d.z6, d.z7, d.z8, d.z9, d.z10]),
                'smooth': calculationMark([d.m1, d.m2, d.m3, d.m4, d.m5, d.m6, d.m7, d.m8, d.m9, d.m10]),
                'anticipation': calculationMark([d.n1, d.n2, d.n3, d.n4, d.n5, d.n6, d.n7, d.n8, d.n9, d.n10]),
                'cityr': Math.round(d.f1 * sur) + ' %',
                'ocityr': Math.round(d.f4 * sur) + ' %',
                'ocityh': Math.round(d.f5 * sur) + ' %',
                'overspeed': calculationMark([d.o1, d.o2, d.o3, d.o4, d.o5, d.o6, d.o7, d.o8, d.o9, d.o10])
            })
            const resData = !excelExport ? data : prepareExport(data, columns);
            if (typeTruck) {
                if (d.engine && d.dist > 100000) res.push(resData);
            }
            else res.push(resData);
        })
        return res
    } catch (error) {
        console.error(error);
        Sentry.captureException(error)
        return [];
    }
})

const prepareExport = (data, columns) => {
    const newData = {}
    columns.forEach(col => {
        if (col.visible) {
            newData[col.label] = data[col.field]
        }
    })
    return newData
}

export const getRowsSensorsReport = ((r, excelExport, columns, page, pageSize) => {
    try {
        let res = []
        const unitF = localStorage.getItem('unit') && localStorage.getItem('unit') === 'f' ? true : false;
        const convertF = (v) => { if (v) return (v * 9 / 5 + 32).toFixed(1) }
        r.list.forEach((r) => {
            if (r.ucode !== 'default') {
                const stamp = parseISO(r.stamp)
                const time = isToday(stamp) && !excelExport ? format(stamp, 'HH:mm:ss') : (format(stamp, 'dd.MM.yyyy HH:mm:ss'))
                const regexAddress = /<a[^>]*>(.*?)<\/a>/;
                const regexGPS = /href="http:\/\/maps\.google\.com\?q=([\d.]+)%20([\d.]+)"/;
                const addressFull = r.pars.position ? r.pars.position.match(regexAddress)[1].split(',') : null;
                // delete last object in array
                if (addressFull) addressFull.pop()
                const address = addressFull ? addressFull.join(',') : null;
                const GPS = r.pars.position ? r.pars.position.match(regexGPS) : null;
                const data = ({
                    'id': r.id,
                    'stamp': time,
                    'ucode': r.ucode ? r.ucode.split('.')[1] : null,
                    'position': address,
                    'gps': GPS ? (GPS[1] + ', ' + GPS[2]) : null,
                    'speed': r.pars.speed,
                    'tmp0': !unitF ? r.pars.tmp0 : convertF(r.pars.tmp0),
                    'tmp1': !unitF ? r.pars.tmp1 : convertF(r.pars.tmp1),
                    'tmp2': !unitF ? r.pars.tmp2 : convertF(r.pars.tmp2),
                    'tmp3': !unitF ? r.pars.tmp3 : convertF(r.pars.tmp3),
                    'tmp4': !unitF ? r.pars.tmp4 : convertF(r.pars.tmp4),
                    'tmp5': !unitF ? r.pars.tmp5 : convertF(r.pars.tmp5),
                    'tmp5hmd': r.pars.tmp5hmd,
                    'tmp6': !unitF ? r.pars.tmp6 : convertF(r.pars.tmp6),
                    'tmp6hmd': r.pars.tmp6hmd,
                    'tmp7': !unitF ? r.pars.tmp7 : convertF(r.pars.tmp7),
                    'tmp7hmd': r.pars.tmp7hmd,
                    'tmp8': !unitF ? r.pars.tmp8 : convertF(r.pars.tmp8),
                    'tmp8hmd': r.pars.tmp8hmd,
                    'pwr1': r.pars.pwr1,
                    'pwr2': r.pars.pwr2,
                    'bat': r.pars.bat,
                    'acceleration': r.pars.acceleration,
                    'alt': r.pars.alt,
                    'rpm': r.pars.rpm,
                    'tacho': r.pars.tacho,
                    'tankl': r.pars.tankl,
                    'signal': r.pars.signal,
                    'pdop': r.pars.pdop,
                    'network': r.pars.network,
                    'odo': r.pars.odo,
                    'fuel': r.pars.fuel,
                    'tmp001': !unitF ? r.pars.tmp001 : convertF(r.pars.tmp001),
                    'tmp002': !unitF ? r.pars.tmp002 : convertF(r.pars.tmp002),
                    'tmp003': !unitF ? r.pars.tmp003 : convertF(r.pars.tmp003),
                    'tmp004': !unitF ? r.pars.tmp004 : convertF(r.pars.tmp004),
                    'tmp005': !unitF ? r.pars.tmp005 : convertF(r.pars.tmp005),
                    'tmp006': !unitF ? r.pars.tmp006 : convertF(r.pars.tmp006),
                    'tmp007': !unitF ? r.pars.tmp007 : convertF(r.pars.tmp007),
                    'tmp008': !unitF ? r.pars.tmp008 : convertF(r.pars.tmp008),
                    'tmp009': !unitF ? r.pars.tmp009 : convertF(r.pars.tmp009),
                    'tmp010': !unitF ? r.pars.tmp010 : convertF(r.pars.tmp010),
                    'tmp011': !unitF ? r.pars.tmp011 : convertF(r.pars.tmp011),
                    'tmp012': !unitF ? r.pars.tmp012 : convertF(r.pars.tmp012),
                    'tmp013': !unitF ? r.pars.tmp013 : convertF(r.pars.tmp013),
                    'tmp014': !unitF ? r.pars.tmp014 : convertF(r.pars.tmp014),
                    'tmp015': !unitF ? r.pars.tmp015 : convertF(r.pars.tmp015),
                    'tmp016': !unitF ? r.pars.tmp016 : convertF(r.pars.tmp016),
                    'tmp017': !unitF ? r.pars.tmp017 : convertF(r.pars.tmp017),
                    'tmp018': !unitF ? r.pars.tmp018 : convertF(r.pars.tmp018),
                    'tmp019': !unitF ? r.pars.tmp019 : convertF(r.pars.tmp019),
                    'tmp020': !unitF ? r.pars.tmp020 : convertF(r.pars.tmp020),
                    'din1': r.pars.din1,
                    'din2': r.pars.din2,
                })
                const resData = !excelExport ? data : prepareExport(data, columns);
                res.push(resData)
            }
            else {
                const stamp = parseISO(r.stamp)
                const time = isToday(stamp) ? format(stamp, 'HH:mm:ss') : (format(stamp, 'dd.MM.yyyy HH:mm:ss'))
                const data = ({
                    'id': r.id,
                    'stamp': time,
                    'ucode': r.move ? (r.move === 1 ? 'on' : 'off') : null,
                    'speed': r.pars.speed,
                    'pwr1': r.pars.pwr1,
                    'pwr2': r.pars.pwr2,
                    'bat': r.pars.bat,
                    'din1': r.pars.din1,
                    'din2': r.pars.din2,
                    'tmp5': !unitF ? r.pars.tmp5 : convertF(r.pars.tmp5),
                    'tmp5hmd': r.pars.tmp5hmd,
                })
                const resData = !excelExport ? data : prepareExport(data, columns);
                res.push(resData)
            }
        })
        if (r.list.length > 1) res.sort((a, b) => b.id - a.id);
        // fill empty rows via total count rows from server
        if (!excelExport) {
            const emptyCount = r.count;
            const emptyArray = new Array(Math.max(0, emptyCount)).fill('');
            const startIndex = (page - 1) * pageSize;
            res = emptyArray.slice(0, startIndex).concat(res, emptyArray.slice(startIndex + res.length));
        }
        return res
    } catch (error) {
        console.error(error);
        Sentry.captureException(error)
        return [];
    }
})

export const getRowsStatsVehicles = ((s, devices, excelExport, columns, currency, drivers) => {
    try {
        if (!s) return null
        // console.log(s);
        let res = []
        s.list.forEach((d) => {
            if (d.dist || d.distp) {
                const dev = devices.find(device => device.id === d.device);
                const distTotal = d.dist + (d.distp ? d.distp : 0);
                const distanceTotal = d.distance + (d.distancep ? d.distancep : 0);
                const durTotal = d.dur + (d.durp ? d.durp : 0);
                const fuelTotal = d.fuel + (d.fuelp ? d.fuelp : 0);
                const consb = d.cons > 0 ? d.cons : 0;
                const fuelb = d.fuel > 0 ? d.fuel : consb;
                const consp = d.consp && d.consp > 0 ? d.consp : 0;
                const fuelp = d.fuelp && d.fuelp > 0 ? d.fuelp : consp;
                const consTotal = d.cons + (d.consp ? d.consp : 0);
                const consFuel = fuelTotal > 0 ? fuelTotal : consTotal;
                const avarageFuel = (100000 / distTotal) * (fuelTotal / 1000);
                const avarageCons = (100000 / distanceTotal) * (consTotal / 1000);
                const avarageFuelCons = (avarageFuel > 0 ? avarageFuel : avarageCons)
                const average_fuel_long = (100000 / d.longFuelDist) * ((d.longFuel < 1 ? d.longCons : d.longFuel) / 1000);
                const trendsUp = avarageFuelCons > average_fuel_long ? 'up' : (avarageFuelCons > 0 ? 'down' : null);

                const phmPriceOfLiter = d.gas + d.gasp > 0 && d.phmpay > 0 ? (1 / ((d.gas + d.gasp) / 1000)) * d.phmpay : d.fprice;
                const priceOfKm = ((consFuel * d.phmpay) / (d.gas + d.gasp) / (distTotal)) * 1000;
                const priceOfKmCalc = dev && dev.cons > 0 & phmPriceOfLiter > 0 ? dev.cons * phmPriceOfLiter / 100 : 0;
                const priceOfKmF = priceOfKm ? priceOfKm : priceOfKmCalc;
                const priceOfKmP = ((fuelp * d.phmpay) / (d.gas + d.gasp) / (d.distp)) * 1000;
                const priceKmPc = (((fuelp * d.phmpay) / (d.gas + d.gasp) / (d.distp)) * 1000) * (d.distp / 1000);
                const priceKmP = priceKmPc ? priceKmPc : (d.distp / 1000) * priceOfKmF
                const driver = dev && dev.driver && drivers ? drivers.find(driver => driver.id === dev.driver) : null
                const gasVsFuel = d.tank > 0 && d.lasttank > 0 && fuelTotal > 0 && (d.gas + d.gasp) > 0 ? ((((d.gas + d.gasp) + ((d.tank * 1000) - (d.lasttank * 1000))))) : 0;
                const gasVsFuelFinal = (Math.abs((gasVsFuel - fuelTotal))) / fuelTotal < 0.15 ? fuelTotal - (fuelTotal - (Math.round(((fuelTotal - gasVsFuel) * 0.1)))) : (fuelTotal - gasVsFuel);
                const warningFuel = gasVsFuel > 0 && fuelTotal > 0 ? (Math.abs((gasVsFuel - fuelTotal))) / fuelTotal : 0;
                const warningFuelAvrg = avarageFuelCons > 0 && average_fuel_long > 0 ? ((avarageFuelCons - average_fuel_long) / average_fuel_long) : 0

                const data = ({
                    'nameS': d.devicename,
                    'plateS': d.plate,
                    'drivers': !excelExport ? d.drivers : d.drivers ? d.drivers.join(', ') : '',
                    'stamps': format(parseISO(d.stamp1), 'dd.MM.yyyy HH:mm') + ';' + format(parseISO(d.stamp2), 'dd.MM.yyyy HH:mm'),
                    'distanceTotal': formatDistance(distTotal / 1000) + ' km',
                    'distanceb': d.dist > 0 ? formatDistance(d.dist / 1000) + ' km' : '0,0 km',
                    'distancep': d.distp > 0 ? formatDistance(d.distp / 1000) + ' km' : '0,0 km',
                    'fuelb': fuelb > 0 ? (formatDecimalSeparator(fuelb / 1000) + ' l') : '-',
                    'fuelp': fuelp > 0 ? (formatDecimalSeparator(fuelp / 1000) + ' l') : '-',
                    'fuelTotal': consFuel > 0 ? (formatDecimalSeparator(consFuel / 1000) + ' l') + (fuelTotal > 0 ? ';can' : ';fueling') : '-',
                    'average_fuel': avarageFuelCons > 0 ? (formatDecimalSeparator(avarageFuelCons) + ' l/100km;' + trendsUp + ';' + warningFuelAvrg) : '-',
                    'norm_fuel': dev && dev.cons > 0 ? formatDecimalSeparator(dev.cons) + ' l/100km' : '-',
                    'durTotal': formatDurationOver24(durTotal * 1000, true),
                    'durb': d.dur > 0 ? formatDurationOver24(d.dur * 1000, true) : '00:00',
                    'durp': d.durp > 0 ? formatDurationOver24((d.durp ? d.durp : 0) * 1000, true) : '00:00',
                    'average_fuel_long': average_fuel_long > 0 ? (formatDecimalSeparator(average_fuel_long) + ' l/100km') : '-',
                    'gasb': d.gas > 0 ? formatDecimalSeparator(d.gas / 1000) + ' l' : '-',
                    'gasp': d.gasp > 0 ? formatDecimalSeparator(d.gasp / 1000) + ' l' : '-',
                    'gasTotal': d.gas + d.gasp > 0 ? formatDecimalSeparator((d.gas + d.gasp) / 1000) + ' l' : '-',
                    'phmPay': d.phmpay > 0 ? `${formatDecimalSeparator(d.phmpay)} ${currency.toUpperCase()}` : '-',
                    'phmPriceOfLiter': phmPriceOfLiter > 0 ? `${formatDecimalSeparator(phmPriceOfLiter)} ${currency.toUpperCase()}` : '-',
                    'priceOfKm': priceOfKmF > 0 ? `${formatDecimalSeparator(priceOfKmF)} ${currency.toUpperCase()}` : '-',
                    'priceOfKmP': priceOfKmP ? `${formatDecimalSeparator(priceOfKmP)} ${currency.toUpperCase()}` : '-',
                    'priceKmP': priceKmP > 0 ? `${formatDecimalSeparator(Math.round(priceKmP))} ${currency.toUpperCase()}` : '-',
                    'cls': dev && dev.cls ? dev.cls : '',
                    'devDriver': dev && dev.drivername ? dev.drivername : '',
                    'driverRid': driver ? driver.rid : '',
                    'tacho': formatDistance(d.tacho / 1000) + ' km',
                    'lasttacho': formatDistance(d.lasttacho / 1000) + ' km',
                    'tank': d.tank > 0 ? d.tank + ' l' : '-',
                    'lasttank': d.lasttank > 0 ? d.lasttank + ' l' : '-',
                    'gasVsFuel': d.gas + d.gasp > 0 && fuelTotal > 0 && gasVsFuelFinal !== 0 ? formatDecimalSeparator(gasVsFuelFinal / 1000) + ' l;' + warningFuel : '-',
                })
                if (excelExport) {
                    data.priceKmP = Math.round(priceKmP).toFixed(2).replace(".", ",");
                    data.average_fuel = avarageFuelCons > 0 ? (formatDecimalSeparator(avarageFuelCons) + ' l/100km;' + warningFuelAvrg) : '-'
                    data.fuelTotal = consFuel > 0 ? (formatDecimalSeparator(consFuel / 1000) + ' l') : '-'
                }
                const resData = !excelExport ? data : prepareExport(data, columns);
                res.push(resData);
            }
        })
        return res
    } catch (error) {
        console.error(error);
        Sentry.captureException(error)
        return [];
    }
})

export const getRowsStatsSelection = ((s, devices, excelExport, columns) => {
    try {
        if (!s) return null
        let res = []
        let emptyCount = s.count;
        s.list.forEach((d) => {
            // if (d.dur) {
            const devName = d.device.map(de => { return devices.find(dd => dd.id === de) ? devices.find(dd => dd.id === de).name : '' })
            const devPlate = d.device.map(de => { return devices.find(dd => dd.id === de) ? devices.find(dd => dd.id === de).plate : '' })
            const drivers = d.driver.filter(dr => dr !== 'NULL');
            const driver2 = d.driver2.filter(dr => dr !== 'NULL');
            const purpose = d.purpose.filter(dr => dr !== '' && dr !== ' ' && dr !== 'NULL');
            const start = d.start ? (parseISO(d.start)) : null;
            const end = d.end ? (parseISO(d.end)) : null;
            const date = d.day ? setDayOfYear(new Date(d.day.year, 0, 1), d.day.day) : null;
            const day = date ? format(date, 'dd.MM.yyyy') + ' ' + format(date, 'eeeeee').charAt(0).toUpperCase() + format(date, 'eeeeee').slice(1) : '';
            const durb = d.dur * 1000;
            const durp = d.durp * 1000;
            const dur = durb + durp;
            const durationTotal = start && end ? end - start : null;


            const data = ({
                'name': !excelExport ? devName : devName.join(', '),
                'plate': !excelExport ? devPlate : devPlate.join(', '),
                'drivers': !excelExport ? drivers : drivers.join(', '),
                'driver2': !excelExport ? driver2 : driver2.join(', '),
                'day': day,
                'start': start ? format(start, 'HH:mm') : '',
                'end': end ? format(end, 'HH:mm') : '',
                'purpose': !excelExport ? purpose : purpose.join(', '),
                'distancep': formatDistance(d.distp / 1000) + ' km',
                'distanceb': formatDistance(d.dist / 1000) + ' km',
                'distanceTotal': formatDistance((d.distp + d.dist) / 1000) + ' km',
                'durp': formatDurationOver24(durp),
                'durb': formatDurationOver24(durb),
                'durTotal': formatDurationOver24(dur),
                'durationPark': durationTotal ? formatDurationOver24(dur - durationTotal) : '',
                'durationTotal': durationTotal ? formatDurationOver24(durationTotal) : '',
                'maxSpeed': d.maxspeed + ' km/h',
                'fuelp': d.fuelp,
                'fuel': d.fuel,
            })
            if (excelExport) {
                data.distancep = d.distp / 1000;
                data.distanceb = d.dist / 1000;
                data.distanceTotal = (d.distp + d.dist) / 1000;
            }
            const resData = !excelExport ? data : prepareExport(data, columns);
            res.push(resData);
            // }
            //else emptyCount = emptyCount -1;
        })

        if (!excelExport) {
            const emptyArray = new Array(Math.max(0, emptyCount)).fill(undefined);
            const startIndex = (s.page - 1) * s.pageSize;
            res = emptyArray.slice(0, startIndex).concat(res, emptyArray.slice(startIndex + res.length));
        }
        return res
    } catch (error) {
        console.error(error);
        Sentry.captureException(error)
        return [];
    }
})